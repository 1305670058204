import StepBeta from "./StepBeta";
import {Panel} from "react-bootstrap";
import Label from "../../Label";
import {Strong} from "../../Label/styles";
import {__} from "../../../utils/translationUtils";
import StepLegacy from "./StepLegacy";
import React from "react";
import {isBetaStep} from "../utils";


export const Step = (props) => {

  const {step} = props

  return (
    <>
      {
        isBetaStep(step) ?
          (
            <StepBeta {...props} />
          ) :
          (
            <>
              <Panel.Heading>
                <Label
                  className="control-label"
                  label={<Strong>{__(step.title)}</Strong>}
                  description={step.description && __(step.description)}
                />
              </Panel.Heading>
              <Panel.Body>
                <StepLegacy
                  {...props}
                  process={props.activeProcess}
                  initialValues={props.initialValues()}
                />
              </Panel.Body>
            </>
          )
      }
    </>
  )
}

export default Step
