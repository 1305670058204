import {__} from "../../../utils/translationUtils";
import {ControlOutlined, SortAscendingOutlined, TableOutlined} from "@ant-design/icons";
import {Alert, Form, Space} from "antd";
import {Button} from "components";
import SortForm from "./SortForm";
import {BatModal} from "../index";
import React, {useEffect, useMemo, useState} from "react";
import {SmartFooter} from "../../SmartFooter";
import {getSearchFields} from "../../../pages/RemoteObjectInstances/utils";
import SearchParameterForm from "./SearchParameterForm";
import {countFiltersDiff, countMissingRequiredSearchAttributes, getSortHeaderMessage} from "./utils";
import ColumnsForm from "./ColumnsForm";
import {FormRef} from "../../../temporary/temporaryExports";
import styled from "styled-components/macro";


type SubmitProps = {
  search?: FormValues // [key: attributeId]: value
  sort?: SortAttribute[]
  columns?: string[] // attributeId[]
  pagination?: Pagination
  updateSort?: boolean
  updateData?: boolean
}

type TableFiltersProps = {
  isLoading: boolean
  configuration: AbstractConfiguration<SearchAttribute, SortAttribute, DisplayAttribute>

  currentSearch: FormValues
  searchModalOpen: boolean
  setSearchModalOpen: (open: boolean) => void
  setMissingRequiredSearchAttributes: (missingRequired: number) => void

  currentSort: SortAttribute[]
  tableColSort?: SortAttribute[]
  sortModalOpen: boolean
  setSortModalOpen: (open: boolean) => void

  summaryDisplayAttributesItems: { value: string, label: string }[]
  visibleColumns: string[]

  pagination: Pagination

  submitSearch: (submitProps: SubmitProps) => void
  enumValues: any
  getAttribute: (attributeId: string) => (Attribute | undefined)
}

const TableFilters = (props: TableFiltersProps) => {
  const {
    isLoading,
    configuration,

    currentSearch,
    searchModalOpen,
    setSearchModalOpen,
    setMissingRequiredSearchAttributes,

    currentSort,
    tableColSort,
    sortModalOpen,
    setSortModalOpen,

    summaryDisplayAttributesItems,
    visibleColumns,

    pagination,
    submitSearch,
    enumValues,
    getAttribute,
  } = props

  const { searchAttributes, sortAttributes } = configuration || {}

  const [searchForm] = Form.useForm()
  const defaultSearchValues = useMemo(() => getSearchFields(searchAttributes), [searchAttributes])
  const [search, setSearch] = useState(currentSearch)
  useEffect(() => { setSearch(currentSearch) }, [currentSearch])
  const editedSearchAttributes = useMemo(() => countFiltersDiff(search, defaultSearchValues), [search, defaultSearchValues])

  const sortForm = React.useRef<FormRef>()
  const [sort, setSort] = useState(currentSort)
  useEffect(() => { setSort(currentSort) }, [currentSort])
  const editedSortAttributes = useMemo(() => sortAttributes && countFiltersDiff(sort, sortAttributes), [sort, sortAttributes])

  const columnForm = React.useRef<FormRef>()
  const [columns, setColumns] = useState(visibleColumns)
  const [columnModalOpen, setColumnModalOpen] = useState(false)
  useEffect(() => { setColumns(visibleColumns)}, [visibleColumns])
  const editedSelectedAttributes = useMemo(() => summaryDisplayAttributesItems.map((a) => (a.value)).length - columns.length, [columns, summaryDisplayAttributesItems])

  useEffect(() => {
    tableColSort && sortForm.current?.setValue('sort', currentSort)
  }, [tableColSort]);

  return (
    <Space className="left" style={{marginLeft: "15px"}}>
      <BatModal
        buttonBadgeCount={editedSearchAttributes}
        disabled={!searchAttributes?.length}
        buttonProps={{icon: <ControlOutlined/>}}
        title={__('Filters')}
        width={700}
        forceOpen={searchModalOpen}
        setForceOpen={setSearchModalOpen}
        onSubmitCallback={() => searchForm.submit()}
        onCancelCallback={() => {
          searchForm.setFieldsValue(currentSearch)
          setMissingRequiredSearchAttributes(countMissingRequiredSearchAttributes(searchForm.getFieldsValue(), searchAttributes))
        }}
        footer={
          <SmartFooter
            onSubmit={() => {
              searchForm.submit()
            }}
            onReset={() => searchForm.setFieldsValue(defaultSearchValues)}
          />
        }
      >
        <SearchParameterForm
          fields={searchAttributes}
          getAttribute={getAttribute}
          form={searchForm}
          enumerations={enumValues}
          initialValues={currentSearch}
          onValuesChange={() => setMissingRequiredSearchAttributes(countMissingRequiredSearchAttributes(searchForm.getFieldsValue(), searchAttributes))}
          onFinish={(values: FormValues) => {
            setSearch(values)
            submitSearch({
              search: values,
              sort: tableColSort || sort,
              columns: columns,
              pagination: pagination,
              updateSort: !tableColSort
            })
            setSearchModalOpen(false)
          }}
        />
      </BatModal>
      <BatModal
        buttonBadgeCount={!!tableColSort || editedSortAttributes}
        showBadgeCount={false}
        forceOpen={sortModalOpen}
        setForceOpen={setSortModalOpen}
        title={__('Sorting')}
        size='small'
        disabled={!sortAttributes?.length}
        buttonProps={{icon: <SortAscendingOutlined/>}}
        onSubmitCallback={() => sortForm.current?.submit()}
        onCancelCallback={() => sortForm.current?.setValue('sort', currentSort )}
        footer={
          <>
            <Button
              title={__('Reset')}
              type="text"
              onClick={() => sortForm.current?.setValue('sort', sortAttributes )}
              disabled={isLoading}
            />
            <Button
              form="sort-form-id"
              title={__("Apply Sorting")}
              type="filled"
              className="right"
              disabled={isLoading}
            />
          </>
        }
      >
        <SortForm
          value={sort}
          header={!!tableColSort && <Alert message={getSortHeaderMessage(tableColSort?.[0], (id) => getAttribute(id))} showIcon />}
          onSubmit={(items) => {
            setSortModalOpen(false)
            const colSort = !!(tableColSort && items.every(({ enabled }) => !enabled)) && tableColSort
            setSort(items)
            submitSearch({
              search: search,
              sort: colSort || items,
              columns: columns,
              pagination,
              updateSort: !colSort
            })
          }}
          componentRef={sortForm}
          getAttribute={getAttribute}
        />
      </BatModal>
      <ColumnsModal
        buttonBadgeCount={editedSelectedAttributes}
        showBadgeCount={false}
        forceOpen={columnModalOpen}
        setForceOpen={setColumnModalOpen}
        title={__('Columns')}
        size='small'
        disabled={!sortAttributes?.length}
        buttonProps={{icon: <TableOutlined />}}
        onSubmitCallback={() => columnForm.current?.submit()}
        onCancelCallback={() => columnForm.current?.setValue('columns', visibleColumns)}
        footer={
          <>
            <Button
              title={__('Reset')}
              type="text"
              onClick={() => columnForm.current?.setValue('columns', summaryDisplayAttributesItems.map(({ value }) => value))}
              disabled={isLoading}
            />
            <Button
              form="columns-form-id"
              title={__("Apply")}
              type="filled"
              className="right"
              disabled={isLoading}
            />
          </>
        }
      >
        <ColumnsForm
          items={summaryDisplayAttributesItems}
          value={columns}
          componentRef={columnForm}
          onSubmit={(value: string[]) => {
            setColumnModalOpen(false)
            setColumns(value)
            submitSearch({
              search: search,
              sort: tableColSort || sort,
              columns: value,
              pagination: pagination,
              updateSort: !tableColSort
            });
          }}
        />
      </ColumnsModal>
      {
        (!!editedSearchAttributes || !!editedSortAttributes || !!editedSelectedAttributes) && (
          <Button
            type="text"
            title={__('reset_filters', "capitalize_sentence")}
            onClick={() => {
              searchForm.setFieldsValue(defaultSearchValues)
              sortForm.current?.setValue('sort', sortAttributes )
              columnForm.current?.setValue('columns', summaryDisplayAttributesItems.map(({ value }) => value))

              submitSearch({
                search: defaultSearchValues,
                sort: sortAttributes,
                columns: summaryDisplayAttributesItems.map(({ value }) => value),
                pagination: {
                  ...pagination,
                  pageSize: configuration?.pageSize,
                  pageNumber: configuration?.pageNumber,
                }
              })
            }}
          />
        )
      }
    </Space>
  )
}

export default TableFilters


const ColumnsModal = styled(BatModal)`
  .ant-modal-body {
    padding: 0!important;
    
    .ant-form > #form-content > div {
      padding: 0
    }
  }
`
