import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import moment from 'moment'
import { getFormValues, submit } from 'redux-form'
import loadingBar from 'nprogress'

import OrderOverviewModal from '../../components/OrderOverviewModal'
import StepInfoModal from '../../components/StepInfoModal'
import SelectPairModal from '../../components/SelectPairModal'
import Loading from '../../components/Loading'
import { validateFileFields } from '../../components/fileUploader/services/utils'

import {
	Step,
	DooTakeOverModal,
	StepButtons,
	StepContainer,
	StepWrapper,
	StickyFooter
} from "../../components/DooOrderPage";

import { consolidateControllerValue } from "../../components/DooOrderPage/Step/StepBeta/NormalStep/payload";
import {getAssignmentPayload} from "../../components/DooOrderPage/Step/StepBeta/AssignmentStep/payload";
import {
  filesController,
  hasUnsupportedController,
  LoadingSkeleton
} from "../../components/DooOrderPage/utils";

import { hashHistory } from '../../providers/HistoryProvider'

import * as config from '../../constants/globalConfiguration'
import { INFO } from "components/dist/Utils/LoggerUtils";

import {
  convertArrayToObject,
  fetchAvailableAssignees,
  putProcessMaster,
  setClickedButton,
  showMessage,
  unClickButtonsAndRemoveErrors
} from '../../utils/appHelper'

import { fetchStep, fetchSteps, putStep } from "../../providers/ApiProvider/order";
import { logout } from '../../providers/ReduxProvider/actions/userActions'
import { countDownMessage } from '../../utils/timerUtils'
import { __ } from '../../utils/translationUtils'
import {api, fetchEntities} from "../../providers/ApiProvider";



class DooOrderPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      orderOverviewModal: { open: false },
      stepInfoModal: { open: false },
      selectPairModal: { open: false },
      steps: null,
      currentStep: null,
      activeStepObject: null,
      isLoading: true,
      userAssignmentPairs: [],
      stepLoading: false,
      selectedAppointmentDetails: {},
      dooTakeoverModal: { open: false }
    }

    this.formRef = React.createRef()
    this.stepRef = React.createRef()
    this.queryString = parse(props.location.search)
    this.defaultDocumentTitle = document.title
  }

  componentDidMount() {
    this.initDooOrderPage()
  }

  initDooOrderPage() {
    const { guiUser, order, activeProcess } = this.props;
    // Change title of the document if the request has gone well
    document.title = `${this.defaultDocumentTitle} - ${order.title ? order.title.toString() : ''}`

    if (
      activeProcess?.ownership?.masterEntityId &&
      activeProcess?.ownership?.masterUserId &&
      activeProcess?.ownership?.masterUserId !== guiUser?.id
    ) {
      this.setState({dooTakeoverModal: {open: true}})
    } else {
      this.confirmSetMaster()
    }
  }

  // Make the title turn back to the default one
  componentWillUnmount() {
    document.title = this.defaultDocumentTitle
  }

  confirmSetMaster() {
    const { guiUser, activeProcess } = this.props;

    this.setState({ dooTakeoverModal: { open: false } });

    if (activeProcess?.ownership?.masterUserId === guiUser.id) {
      api.defaults.headers.common['x-entity-id'] = activeProcess?.ownership?.masterEntityId
      this.initCurrentStep()
      return
    }

    // get user's occurrences in the ownership's assignees list.
    const assignees = (activeProcess?.ownership?.assignees || []).filter((p) => p.userId === guiUser.id)

    if (assignees.length) { // user is in ownership Assignees list.
      if(assignees.length === 1) {
        this.setMaster(assignees[0])
      } else {
        const entityIds = assignees.map(({ entityId }) => entityId)

        fetchEntities({ ids: entityIds.join() })
          .then((entities) => {
            const entitiesMap = convertArrayToObject(entities, 'id')
            const assigneesWithEntity = assignees.map((assignee) => ({
              ...assignee,
              entity: entitiesMap[assignee.entityId],
              user: guiUser
            }))

            this.setState({ userAssignmentPairs: assigneesWithEntity }, () => this.setState({ selectPairModal: { open: true } }))

          })
      }
    } else { // Not in ownership Assignees list.
      // User has only one assignee pair available.

      fetchAvailableAssignees(activeProcess.id, { userId: guiUser.id })
        .then((response) => {
          this.setState(
            { userAssignmentPairs: response },
            () => {
              if (response?.length === 1) {
                this.setMaster(response[0])
              }
              // User belongs to more than one organisation.
              else {
                // Force him to select which one to use.
                this.setState({ selectPairModal: { open: true } })
              }
            })}
        )
    }
  }

  initCurrentStep() {
    const { activeProcess: { id: processId }, activeProcess } = this.props
    const { currentStepLocation } = activeProcess || {}

    this.setState({ currentStep: currentStepLocation }, () => {
      this.getSteps(processId).then((steps) => {
        const step = this.extractStep(steps, currentStepLocation)
        if (!step) {
          hashHistory.goBack()
          return true
        }

        this.setState({
          isLoading: false,
          activeStepObject: step
        })
        loadingBar.done()
        return true
      })
    })
  }

  async setMaster(pair) {
    const { guiUser, activeProcess } = this.props

    api.defaults.headers.common['x-entity-id'] = pair.entityId

    const data = {
      ...activeProcess.ownership,
      masterUserId: guiUser.id,
      masterEntityId: pair.entityId
    }

    // If pair is missing from the assignees, add it
    const found = _.find(data.assignees, (p) => p.userId === pair.userId && p.entityId === pair.entityId)

    if (!found) {
      data.assignees.push({
        userId: pair.userId,
        entityId: pair.entityId,
        ownershipId: data.id
      })
    }

    // Try setting current user as MasterUser
    return putProcessMaster(activeProcess.id, data)
      .then((res) => {
      if (res) {
        this.initCurrentStep.bind(this)()
      } else {
        hashHistory.goBack()
      }
    })
  }

  handleButtonClick(button, step) {
    window.scrollTo(0, 0)
    switch (parseInt(step.discriminator)) {
      case config.APPONINTMENT_STEP_DISCRIMINATOR:
        this.sendAppointmentStep(button, step)
        return
      case config.ASSIGNMENT_STEP_DISCRIMINATOR:
        this.sendAssignmentStep(button, step)
        return
      case config.REASSIGNMENT_STEP_DISCRIMINATOR:
        this.sendAssignmentStep(button, step)
        return;
      case config.NORMAL_STEP_DISCRIMINATOR:
      default:
        this.sendNormalStep(button, step)
    }
  }

  async getSteps(processId) {
    loadingBar.start()
    this.setState({ isLoading: true })

    return fetchSteps(processId).then((steps) => {
      this.setState({
        isLoading: false,
        steps: _.sortBy(steps, 'position'),
      })
      loadingBar.done()

      return steps
    })
  }

  getStep(id) {
    this.setState({
      activeStepObject: null,
      stepLoading: true,
      isLoading: true
    })

    return fetchStep(id)
      .then((step) => {
        this.setState({
          activeStepObject: step,
          stepLoading: false,
          isLoading: false
        })
      })
      .catch((e) => {
        hashHistory.goBack()
        return true
      })
  }

  async sendAssignmentStep(button, step) {

    if (!button) {
      console.error('No button in the send appointment step seems to be clicked');
      return false
    }

    // Set button as clicked
    const _step = {...step}
    setClickedButton(_step, button.name)

    const {assignees, validationStatus} = await getAssignmentPayload({button, formRef: this.formRef})

    const {validationFormRequired = true} = button

    if (validationFormRequired && (!validationStatus || validationStatus === 'failed')) {
      return
    }

    _step.attributes.assignees = assignees || []

    loadingBar.start()
    this.setState({
      stepLoading: true,
      isLoading: true
    })

    putStep(this.state.activeStepObject.id, _step)
      .then((data) => {
        const { result } = data
        let hasToBeRedirected = false

        if (typeof result !== 'undefined' && result.length > 0) {
          hasToBeRedirected = this.doActions(result)
        }
        if (!hasToBeRedirected) {
          // I update the state only if the user remains in the same page
          this.setState({
            stepLoading: false,
            isLoading: false
          })
          loadingBar.done()
        }
      })
      .catch(() => {
        loadingBar.done()
        this.setState({
          stepLoading: false,
          isLoading: false
        })
      })
  }

  setAppointmentDetails = () => {
    const { appointmentFormValues } = this.props
    const { selectedAppointmentDetails } = this.state

    const appointmentDetails = { ...selectedAppointmentDetails }

    if (typeof appointmentFormValues === 'undefined'
      || !appointmentFormValues
      || typeof appointmentFormValues.assignees === 'undefined'
      || appointmentFormValues.assignees === '[]'
      || appointmentFormValues.assignees.length === 0) {
      showMessage('error', __('NoAssigneesSelected'))

      return false
    }

    const _assignees = JSON.parse(appointmentFormValues.assignees)

    appointmentDetails.assignees = _assignees.map((assignee) => {

      const { type } = assignee
      return type === 'USER' ?
        {
          userId: assignee.userId,
          entityId: assignee.entityId,
          type: 'USER'
        } :
        {
          entityId: assignee.id,
          type: 'ENTITY_ATTACHED_USERS'
        }
    })

    appointmentDetails.startingDatetime = appointmentFormValues.start
    appointmentDetails.endingDatetime = appointmentFormValues.end

    this.setState({ selectedAppointmentDetails: appointmentDetails })

    return true
  }

  sendAppointmentStep(button, step) {
    const { appointmentFormValues } = this.props
    const { selectedAppointmentDetails, activeStepObject } = this.state

    if (!step) {
      step = activeStepObject
    }

    // Set button as clicked
    const _step = { ...step }

    if (!button) {
      return this.setAppointmentDetails()
    }

    this.closeModal()

    loadingBar.start()
    this.setState({
      stepLoading: true,
      isLoading: true
    })

    setClickedButton(_step, button.name)

    if (button.validationFormRequired) {
      if (!selectedAppointmentDetails
        || !selectedAppointmentDetails?.assignees
        || selectedAppointmentDetails?.assignees === '[]'
        || selectedAppointmentDetails?.assignees?.length === 0
      ) {
        showMessage('error', __('NoAssigneesSelected'))
        loadingBar.done()
        this.setState({
          stepLoading: false,
          isLoading: false
        })
        return false
      }
      if (!selectedAppointmentDetails
        || !selectedAppointmentDetails?.startingDatetime
        || !selectedAppointmentDetails?.endingDatetime
      ) {
        showMessage('error', __('NoAppointmentSelected'))
        loadingBar.done()
        this.setState({
          stepLoading: false,
          isLoading: false
        })
        return false
      }
    }

    _step.attributes.assignees = selectedAppointmentDetails?.assignees
    _step.attributes.startingDatetime = selectedAppointmentDetails?.startingDatetime
    _step.attributes.endingDatetime = selectedAppointmentDetails?.endingDatetime

    putStep(step.id, step)
      .then((data) => {
        const { result } = data
        let hasToBeRedirected = false

        if (typeof result !== 'undefined' && result.length > 0) {
          hasToBeRedirected = this.doActions(result)
        }
        if (!hasToBeRedirected) {
          // I update the state only if the user remains in the same page
          this.setState({
            stepLoading: false,
            isLoading: false
          })
          loadingBar.done()
        }
      })
      .catch(() => {
        loadingBar.done()
        this.setState({
          stepLoading: false,
          isLoading: false
        })
      })
  }

  async sendNormalStep(button, step) {

    const { activeStepObject } = this.state

    let _activeStepId
    let _step

    if (step.discriminator === config.NORMAL_STEP_DISCRIMINATOR.toString() && !hasUnsupportedController(step)) {

      const { validationFormRequired = true } = button
      const { submit } = this.formRef?.current || {}
      const { validationStatus, values } = await submit?.({ validation: validationFormRequired })

      if (validationFormRequired && (!validationStatus || validationStatus === 'failed')) {
        return
      }

      _activeStepId = activeStepObject.id
      loadingBar.start()
      this.setState({
        stepLoading: true,
        isLoading: true
      })

      _step = { ...step }

      for (const fieldIndex in _step.fields) {

        const field = _step.fields[fieldIndex] || {}
        const { id } = field

        _step.fields[fieldIndex].destination = consolidateControllerValue(values[id], field)

        // TODO: better handle modification to field options
        if (_step.fields[field]?.controllerOptions.objectConfiguration) {
          _step.fields[field].controllerOptions.objectConfiguration = values[id].objectConfiguration
        }
      }

      setClickedButton(_step, button.name)

    }
    else {
      // TODO: only executed when unsupported fields (e.g Billing, BillingController)
      let stepFormErrors = this.props.reduxState.form.stepForm.syncErrors || null

      // -------------------- Validate File fields, since there are not inside a <Field of redux ---------------------//
      const fileFields = (step.fields || []).filter((f) => filesController.includes(f.controllerType))

      const errorFileFields = validateFileFields(fileFields)

      if (errorFileFields) {
        // this just to make a return in case of error
        stepFormErrors = { ...(stepFormErrors || {}), ...errorFileFields }
        const fileFieldsWithError = {}
        Object.entries(errorFileFields)
          .forEach(([fieldId, error]) => {
            const activeField = activeStepObject.fields.find((f) => f.id === fieldId)
            if (activeField == null) {
              return null
            }
            fileFieldsWithError[fieldId] = {
              ...activeField,
              error
            }
          })

        const fileFiledIds = Object.keys(fileFieldsWithError)
        this.setState({
          activeStepObject: {
            ...activeStepObject,
            fields: activeStepObject.fields.map((field) => (fileFiledIds.includes(field.id) ? fileFieldsWithError[field.id] : field))
          }
        })
      } else {
        step.fields.forEach((f) => delete f.error)
      }
      // ------------------------------------------------------------------------------------------------//

      this.props.submitAction('stepForm')
      if (button.validationFormRequired && stepFormErrors) {
        return
      }
      _activeStepId = activeStepObject.id
      loadingBar.start()
      this.setState({
        stepLoading: true,
        isLoading: true
      })

      _step = { ...step }
      setClickedButton(_step, button.name)

      for (const field in _step.fields) {
        let val = this.props.stepFormValues[_step.fields[field].id]
        // Todo: Need to get rid of this. We need to check where this is being used.
        if (val == 'true') {
          val = true
        }
        if (val == 'false') {
          val = false
        }
        if (Array.isArray(val)) {
          val = val.join()
        }
        if (typeof val === 'string') {
          try {
            //added for billing retrocompatibility
            let jsonVal = JSON.parse(val)
            if (jsonVal && jsonVal.isCompatibilityMode && jsonVal.priceCategories) {
              val = JSON.stringify(jsonVal.priceCategories)
            }
          } catch (error) {

          }
        }
        if (typeof val === 'object' && !(val instanceof Date) && !(val instanceof moment)) {
          if (val !== null) {
            val = JSON.stringify(val)
          }
        }

        if (_step.fields[field].controllerType === "ObjectBinder") {
          _step.fields[field].value = val
        } else {
          _step.fields[field].destination = val
        }
      }
    }

    putStep(_activeStepId, _step)
      .then((data) => {
        const { result } = data
        let hasToBeRedirected = false
        if (typeof result !== 'undefined' && result.length > 0) {
          hasToBeRedirected = this.doActions(result)
        }
        if (!hasToBeRedirected) {
          // I update the state only if the user remains in the same page
          this.setState({
            stepLoading: false,
            isLoading: false,
          })
          loadingBar.done()
        }
      })
      .catch(() => {
        loadingBar.done()
        unClickButtonsAndRemoveErrors(_step)
        this.setState({
          stepLoading: false,
          isLoading: false,
        })
      })
  }

  doActions(actions) {
    const { orderId } = this.props
    const { steps } = this.state
    let userHasBeenRedirected = false
    let moved = false
    actions.forEach(async (action) => {
      switch (action.discriminator) {
        case config.discriminators.GO_TO:
          const currentStep = parseInt(action.value)
          loadingBar.start()
          this.setState({ currentStep }, () => {
            this.getStep(this.extractStep(steps, currentStep).id).then(() => {
              loadingBar.done()
            })
          })
          break

        case config.discriminators.REJECT: // Return to listing (Artiom's request)
          if (!moved) {
            hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
            userHasBeenRedirected = true
          }
          moved = true
          break

        case config.discriminators.COMPLETE: // Complete
          if (!moved) {
            hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
            userHasBeenRedirected = true
          }
          moved = true
          break

        case config.discriminators.MESSAGE: // Show Message
          showMessage('info', __(action.value))
          break

        case config.discriminators.ALERT: // Show Error
          showMessage('error', __(action.value))
          break

        case config.discriminators.RETURN: // Return to listing
          if (!moved) {
            hashHistory.push(
              this.queryString.backUrl || config.ordersDefaultUrl
            )
            userHasBeenRedirected = true
          }
          moved = true
          break

        case config.discriminators.GO_TO_NEXT_PROCESS: // Refresh doo page for current order(and show new active process)
          if (!moved) {
            INFO('redirecting', `/orders/${orderId}/doo?backUrl=${this.queryString.backUrl}&reload=1`)
            hashHistory.push(`/orders/${orderId}/doo?backUrl=${this.queryString.backUrl}&reload=1`)
            window.location.reload()
          }
          moved = true
          break

        case config.discriminators.LOGOUT: // Refresh doo page for current order(and show new active process)
          if (!moved) {
            if (action.message?.value) {
              countDownMessage(action.message?.toBeTranslated ? __(action.message?.value) : action.message?.value, action.delay)
            }
            setTimeout(() => {
              hashHistory.push(config.ordersDefaultUrl)
              this.props.logoutAction()
            }, action.delay);
          }
          moved = true
          break
      }
    })
    return userHasBeenRedirected
  }

  extractStep(steps, currentStepPosition) {
    return _.find(steps, (step) => step.position === currentStepPosition)
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      orderOverviewModal: { open: false },
      stepInfoModal: { open: false },
      selectPairModal: { open: false }
    })
  }

  closeDooTakeoverModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      dooTakeoverModal: { open: false }
    });
    hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
  }

  initialValuesLegacy() {
    const { activeStepObject } = this.state
    const obj = {}
    activeStepObject.fields.map((field) => {
      if (field.controllerType === 'DateInput' || field.controllerType === 'DateTimeInput') {
        // By default set current datetime or source if exists.
        obj[field.id] = (field.source) ? moment(field.source).toDate() : null
        // If source == "null" or missing leave input blank (Mantis 1387)
        if (field.source === 'null' || typeof field.source === 'undefined') {
          obj[field.id] = null
        }
      } else if (field.controllerType === 'SimpleCheckBox') {
        let val = ''
        if (typeof field.defaultValue !== 'undefined') {
          val = field.defaultValue.toLowerCase()
        }

        if (typeof field.source !== 'undefined') {
          val = field.source.toLowerCase()
        }

        obj[field.id] = val
      } else {
        obj[field.id] = (field.source) ? field.source : field.defaultValue
      }
    })
    if (activeStepObject.discriminator == 1
      || activeStepObject.discriminator == 2
      || activeStepObject.discriminator == 3) {
      obj.assignees = []
    }

    return obj
  }

  handleDocumentEditCompanySelect(value) {
    const _step = { ...this.state.activeStepObject }
    const _field = _.find(_step.fields, (f) => f.controllerType === 'DocumentEditor')
    _field.attributes.documentEditorSettings.selectedCompany = value
    this.setState({ activeStepObject: _step })
  }

  handleSubmitAppointment() {
    return this.sendAppointmentStep()
  }

  render() {
    const {
      guiUser,
      order,
      activeProcess,
      stepFormValues
    } = this.props
    const {
      isLoading,
      stepLoading,
      activeStepObject: step,
      orderOverviewModal,
      stepInfoModal,
      selectPairModal,
      userAssignmentPairs,
      dooTakeoverModal,
    } = this.state

    if (step && stepFormValues) {
      step.fields = step.fields.map((f) => ((filesController.includes(f.controllerType) && stepFormValues[f.id]) ? {
        ...f,
        source: stepFormValues[f.id]
      } : f))
    }

    const commonStepProps = {
      step,
      activeProcess,
      order,
      guiUserId: guiUser.id,
      openOrderOverview: () => this.setState({ orderOverviewModal: { open: true } }),
      formRef: this.formRef,
	    stepRef: this.stepRef,
	    // Legacy props
	    onSubmit: () => { },
	    initialValues: this.initialValuesLegacy.bind(this),
	    setAppointmentDetails: this.setAppointmentDetails.bind(this),
	    handleDocumentEditCompanySelect: this.handleDocumentEditCompanySelect.bind(this)
    }

    return (
      <div id="main-content">
        <Loading loading={isLoading} />
        {step && !isLoading && (
          <StepWrapper>
            <StepContainer ref={this.stepRef}>
              {
                !stepLoading && (<Step {...commonStepProps} />)
              }
              <StickyFooter>
                <StepButtons parent={this} step={step} />
              </StickyFooter>
            </StepContainer>
          </StepWrapper>
        )}

        {isLoading && <LoadingSkeleton />}

        <DooTakeOverModal
          open={dooTakeoverModal.open}
          closeModal={this.closeDooTakeoverModal.bind(this)}
          onConfirm={this.confirmSetMaster.bind(this)}
          masterUserInfo={(activeProcess?.ownership?.masterUserId) ? activeProcess?.ownership?.master?.username : null}
          notBeforeInfo={activeProcess?.notBefore}
        />

        {order && (
          <OrderOverviewModal
            open={orderOverviewModal.open}
            params={{ orderId: order.id }}
            order={order}
            closeModal={this.closeModal.bind(this)}
          />
        )}

        {order && step && (
          <StepInfoModal
            open={stepInfoModal.open}
            params={{ info: step.description }}
            order={order}
            closeModal={this.closeModal.bind(this)}
          />
        )}

        {activeProcess && userAssignmentPairs && selectPairModal.open && (
          <SelectPairModal
            open={selectPairModal.open}
            onSubmit={this.setMaster.bind(this)}
            userAssignmentPairs={userAssignmentPairs}
            closeModal={this.closeModal.bind(this)}
            processId={activeProcess && activeProcess.id}
            guiUserId={guiUser.id}
          />
        )}
      </div>

    )
  }
}

DooOrderPage.props = {
  dispatch: PropTypes.func,
  submit: PropTypes.func,
  location: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  activeProcess: PropTypes.object,
  reduxState: PropTypes.object,
  guiUser: PropTypes.object,
  stepFormValues: PropTypes.object,
  appointmentFormValues: PropTypes.object
}

export default connect(
  (state) => ({
    reduxState: state,
    stepFormValues: getFormValues('stepForm')(state),
    appointmentFormValues: getFormValues('appointmentForm')(state)
  }),
  { submitAction: submit, logoutAction: logout }
)(DooOrderPage)
