import React, {Component} from 'react';
import PropTypes from 'prop-types';
import objectAssign from 'object-assign';
import {addAsterisks, showMessage} from '../../utils/appHelper';
import Pagination from "react-js-pagination";
import Glyphicon from '@strongdm/glyphicon'
import _ from 'lodash';
import {MAX_PAGE_SIZE} from "../../constants/globalConfiguration";
import { api } from '../../providers/ApiProvider'
import { __ } from '../../utils/translationUtils'
import {Button} from "components";

const loadingBar = require('nprogress');

class AssignmentField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableAssignees: [],
      assigneesOfEntity: [],
      selectedAssignees: [],
      pagination: {
        pageNumber: 1,
        pageSize: 5,
        totalCount: 0,
        entities: null,
      },
      filters: {
        q: null,
        entities: null,
      }
    };

    // Setting Refs
    this.searchInputFieldRef = null;
    this.availableAssigneeRef = null;
    this.selectedAssigneeRef = null;
    this.buttonSearchSubmitRef = null;
    this.checkToggleAvailableAssignee = null;
    this.assigneeSelectedBefore = [];

    // Setting entity change to true so the api request will be got at the component did mount
    this.entityChanged = true;

    // Binding methods
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAllEntities = this.getAllEntities.bind(this);
    this.getPairs = this.getPairs.bind(this);
    this.addPair = this.addPair.bind(this);
    this.removePair = this.removePair.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderAvailableOptions = this.renderAvailableOptions.bind(this);
    this.setSelectedAssignees = this.setSelectedAssignees.bind(this);
    this.handleChangeWithProps = this.handleChangeWithProps.bind(this);
    this.toggleSelectedAssigneeAvailable = this.toggleSelectedAssigneeAvailable.bind(this);
  }

  componentDidMount() {
    this.handleChangeWithProps();
    this.setSelectedAssignees();
    this.getPairs();
    this.getAllEntities();
    this.settingCustomHTMLAttributes();
  }

  /**
   * I used this function to set node attributes in order to
   * **/
  settingCustomHTMLAttributes() {
    this.searchInputFieldRef && this.searchInputFieldRef.setAttribute('cy-test', 'searchInputField');
    this.availableAssigneeRef && this.availableAssigneeRef.setAttribute('cy-test', 'availableAssigneeRef');
    this.selectedAssigneeRef && this.selectedAssigneeRef.setAttribute('cy-test', 'selectedAssigneeRef');
    this.buttonSearchSubmitRef && this.buttonSearchSubmitRef.setAttribute('cy-test', 'buttonSearchSubmitRef');
  }

  /**
   * Method used to set selectedAssignees in state
   * Boma01 02 04 2019
   * **/
  setSelectedAssignees() {

    const {selectedItems} = this.props;
    this.setState({
      selectedAssignees: selectedItems,
    });
  }

  getPairs() {

    const {processId} = this.props;
    const {
      pagination: {pageSize, pageNumber},
      filters: {q, entities},
    } = this.state;

    if (!processId) {
      return;
    }

    this.setState(() => {
      return {isLoading: true};
    });

    loadingBar.start();

    if (this.entityChanged){
      // Getting all the user for the entity, I need it when i select all the assignees to the organisation
      api.get(`/processes/${processId}/ownership/availableAssignees?${entities && `&belongsToIds=${entities}` || ''}`)
        .then( response => {
          this.entityChanged = false
          // Setting checkToggleAvailableAssignee as unchecked
          this.assigneeSelectedBefore = []
          if(this.checkToggleAvailableAssignee) this.checkToggleAvailableAssignee.checked = false
          this.setState({
            assigneesOfEntity: response.data
          })

        })
    }


    api.get(`/processes/${processId}/ownership/availableAssignees?pageNumber=${pageNumber}&pageSize=${pageSize}${q && `&q=${addAsterisks(q)}` || ''}${entities && `&belongsToIds=${entities}` || ''}`)
      .then(
        (response) => {

          this.setState({
            availableAssignees: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done();
          });
        });
  }

  addPair(pair) {
    const {selectedAssignees} = this.state;


    if (this.props.controlData && this.props.controlData.enforceSingleCompany && selectedAssignees.length &&
      _.findIndex(selectedAssignees, {entityId: pair.entityId}) === -1){
      showMessage('warning', pair.user.username+" "+__('UserWithCompanyAlreadySelected'));
      return;
    }

    if (this.props.controlData && this.props.controlData.maxNumberOfAssignees &&
      selectedAssignees.length >= this.props.controlData.maxNumberOfAssignees) {
      showMessage('warning', __('MaxUsersAlreadySelected'));
      return;
    }

    const _selected = [].concat(selectedAssignees);
    _selected.push(pair);
    this.setState({
      selectedAssignees: _selected,
    }, () => {
      this.handleChange();
    });
  }

  getAllEntities() {

    const {processId} = this.props;

    this.setState({isLoading: true});
    return api.get(`/processes/${processId}/ownership/availableAssignees?pageSize=${MAX_PAGE_SIZE}`)
      .then(
        response => {
          let entities = [].concat(response.data.map(a => a.entity))
          entities = Array.from(new Set(entities.map( e => e.id))).map( eid => entities.find(e => e.id === eid));
          this.setState({entities: entities});
        },
        error => {
          console.log(error);
        }
      );
  }

  removePair(pair) {
    const {selectedAssignees} = this.state;
    const _selected = [].concat(selectedAssignees);
    const idx = _.findIndex(selectedAssignees, pair);
    _selected.splice(idx, 1);

    if (!_selected.length){
      showMessage('warning', 'You should have at least one Assignee')
      return;
    }

    this.setState({
      selectedAssignees: _selected,
    }, () => {
      this.handleChange();
    });
  }

  handleChange() {

    const assigneesToSubmit = [];
    for (const assignee of this.state.selectedAssignees){
      assigneesToSubmit.push(assignee)
    }

    this.props.input.onChange(JSON.stringify(assigneesToSubmit));
  }

  handleChangeWithProps() {
    this.props.input.onChange(JSON.stringify(this.props.selectedItems));
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          pageNumber: 1,
        }
      )
    }, () => {
      this.getPairs();
    });
  }

  handlePageChange(page) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          pageNumber: page,
        }
      )
    }, () => {
      this.getPairs();
    });
  }

  renderAvailableOptions() {
    const {availableAssignees, selectedAssignees} = this.state;
    return (
      <ul className="pairs" ref={el => this.availableAssigneeRef = el}>
        {availableAssignees.map((p) => {
          // Mattia 28-03-2019 I changed this because doing something like _.findIndex(selectedAssignees, p) doesn't
          // work well with the changes i did  before
          const idx = _.findIndex(selectedAssignees, {userId: p.userId, entityId: p.entityId});
          return (
            <li
              className={`pair ${(idx > -1) ? 'pair--sleected' : ''}`}
              onDoubleClick={() => {
                this.addPair(p);
              }}
              key={`key-${p.userId}-${p.entityId}`}
            >
              <span>{p.user && p.user.username}</span> - <span
              className="discreet">{p.entity && p.entity.shortName}</span> {(idx > -1) && <span>&#10003;</span>}
              <span
                className="toggle-element pull-right"
                onClick={() => {
                  this.addPair(p);
                }}
              >
                <Glyphicon glyph="plus"/>
              </span>
            </li>
          );
        })}

      </ul>
    );
  }

  renderSelectedOptions() {
    const {selectedAssignees} = this.state;
    return (
      <ul className="pairs" ref={el => this.selectedAssigneeRef = el}>
        {selectedAssignees.map((p) => {
          return (
            <li
              className="pair"
              onDoubleClick={() => {
                this.removePair(p);
              }}
              key={`key-${p.userId}-${p.entityId}`}
            >
              {p.user && p.user.username} - <span className="discreet">{p.entity && p.entity.shortName}</span>
              <span
                className="toggle-element pull-right"
                onClick={() => {
                  this.removePair(p);
                }}
              >
                <Glyphicon glyph="remove"/>
              </span>
            </li>
          );
        })}
        {
          selectedAssignees.length === 0 &&
          <li className="pair">
            <i className="discreet">{__('No items selected yet')}</i>
          </li>
        }
      </ul>
    );
  }

  toggleSelectedAssigneeAvailable() {
    if (this.assigneeSelectedBefore.length){
      this.setState({selectedAssignees: this.assigneeSelectedBefore},
        () => {
        this.assigneeSelectedBefore = [];
        this.handleChange()
      });
    }else {
      this.assigneeSelectedBefore = this.state.selectedAssignees;

      // Check if in this operation the max number of assignees is reached
      // All the other checking in addPair function is useless because that warning can only
      // happen when you can select multiple organisations
      if (this.props.controlData && this.props.controlData.maxNumberOfAssignees &&
        this.state.assigneesOfEntity.length >= this.props.controlData.maxNumberOfAssignees) {
        showMessage('warning', `The max number of assignees is reached`);
        return;
      }
      this.setState({selectedAssignees: this.state.assigneesOfEntity},
        () => {
        this.handleChange()
      });
    }
  }

  render() {

    const {
      pagination: {totalCount, pageSize, pageNumber},
      entities,
      selectedAssignees,
    } = this.state;

    const { fullWidth } = this.props

    return (
      <div className="row assignment-control">
        <div className={`col-xs-12 ${fullWidth ? 'col-sm-12' : 'col-sm-7'}` }>
          <h5>
            {__('Available assignees')}: <strong>{totalCount}</strong>
          </h5>

          <form onSubmit={this.handleSubmit} style={{ marginLeft: fullWidth ? '-1.5rem' : '' }}>
            <div className="form-group clearfix">
              <div className="col-xs-12 col-sm-5">
                <input
                  className="form-control"
                  type="text"
                  ref={elem => this.searchInputFieldRef = elem}
                  placeholder={`${__('Search')}...`}
                  onChange={
                    e => this.setState({filters: {...this.state.filters, q: e.target.value}})
                  }
                />
              </div>

              <div className="col-xs-12 col-sm-6">
                <select
                  className="form-control"
                  onChange={
                    e => {
                      this.entityChanged = true
                      this.setState({filters: {...this.state.filters, entities: e.target.value}})
                    }
                  }
                >
                  <option value="">{__('Select Organisation')}...</option>
                  {entities && entities.map(entity => (
                    entity.type !== 'user_group'
                  ) && (
                    <option
                      key={`entity-option-${entity.id}`}
                      value={entity.id}
                    >
                      {entity.name}
                    </option>
                  ))}
                </select>

                {this.state.availableAssignees.length > 0 &&
                <div style={{display: "flex", marginTop: '.25rem'}}>
                  <input id="selectAll" type={'checkbox'} style={{cursor: "pointer"}} onChange={this.toggleSelectedAssigneeAvailable} ref={el => this.checkToggleAvailableAssignee = el}/>
                  <label htmlFor="selectAll" className="col-sm-18 control-label" style={{cursor: "pointer", lineHeight: 0, display: "flex", alignItems: "center", marginBottom: 0, whiteSpace: "nowrap", textTransform: "uppercase", marginLeft: '.25rem', marginTop: '.5rem', fontSize: '.875rem'}}>&nbsp;{__('Select all Assignees of this Organisation')}</label>
                </div> || null}

              </div>


              <button
                type="submit"
                className="search-btn btn btn-link pull-left col-sm-1"
                ref={el => this.buttonSearchSubmitRef = el}
                onClick={this.handleSubmit}
              >
                <Glyphicon glyph="search"/>
              </button>

            </div>
          </form>

          {
            totalCount > 0 && (
              <>
                {this.renderAvailableOptions()}
                <Pagination
                  innerClass="pagination pagination-sm"
                  activePage={pageNumber}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                />
              </>
            )
          }
        </div>
        <div className={`col-xs-12 ${fullWidth ? 'col-sm-12' : 'col-sm-5'} ${fullWidth && 'gutter-bottom'}`}>
          <h5 style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <span>
              {__('Selected assignees')}: <strong>{selectedAssignees.length}</strong>
            </span>
            <span>
              {
                !!selectedAssignees.length &&
                (
                  <Button title={__('clear')} type={'text'} onClick={() => this.setState({ selectedAssignees: [] })}/>
                )
              }
            </span>
          </h5>

          <div>
            {this.renderSelectedOptions()}
          </div>
        </div>
      </div>
    );
  }


}

AssignmentField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialValues: PropTypes.array,
  valuePrefix: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  rest: PropTypes.object,
  processId: PropTypes.string,
  selectedItems: PropTypes.array,
  guiUserId: PropTypes.string,
  controlData: PropTypes.object
};
AssignmentField.defaultProps = {
  meta: {
    touched: false,
    error: false,
  },
  rest: {},
  min: 0,
  max: 100,
  step: 1,
  valuePrefix: null,
  initialValues: null,
  selectedItems: [],
  controlData: null
};


/**
 * @deprecated
 */

export default AssignmentField;
