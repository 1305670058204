import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'antd'
import {Button} from 'react-bootstrap'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import moment from 'moment'
import Loader from 'react-loader'
import {connect} from 'react-redux'
import {submit} from 'redux-form'
import {withLastLocation} from 'react-router-last-location'
import EditOrderSelectUserModal from './EditOrderSelectUserModal'
import * as config from '../constants/globalConfiguration'
import {hashHistory} from '../providers/HistoryProvider'
import {showMessage} from '../utils/appHelper'

import {api} from '../providers/ApiProvider'
import Space from "antd/es/space";
import {__, T} from '../utils/translationUtils'
import {checkMatchingTemplates} from "../utils/rightsUtils";
import {UsersField} from "../utils/renderFieldUtils";
import {fetchUsers} from "../providers/ApiProvider/entity";

const loadingBar = require('nprogress')

class EditOrderModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    location: PropTypes.object,
    filterProcesses: PropTypes.bool,
    users: PropTypes.array,
    attributesConfiguration: PropTypes.array,
    order: PropTypes.object,
    orderId: PropTypes.string,
    guiUser: PropTypes.object,
    closeModal: PropTypes.func,
    updateData: PropTypes.func
  };

  constructor(props) {
    super(props)

    this.state = {
      order: null,
      selectedRow: null,
      selectUserModal: {open: false},
      userList: []
    }
  }

  componentDidMount() {
    // this.getOrder();
    this.getProcesses()
  }

  closeSelectUserModal() {
    this.setState({selectUserModal: {open: false}})
  }

  handleRowSelect(row, isSelected) {
    if (isSelected) {
      this.setState({selectedRow: row})
    } else {
      this.setState({selectedRow: null})
    }
  }

  openUserSelectModal() {
    /*
    * Mattia 02-04-2019
    * Change the assignee of the row because it does not include the user and the entity object, which are necessary
    * in the AssignmentField Component render function, in order to show the name of the user and the name of the
    * entity. I choose to put this code here, but if anyone else has a better idea to fix this problem feel free to
    * change
    * */

    if (this.state.selectedRow) {
      // Copying row in a new Object variable
      const newRow = {...this.state.selectedRow}

      // Create the request in order to get the ownership
      const req = `/processes/${newRow.id}/ownership`

      // Call the request
      api.get(req)
        .then((res) => {
          // Change the assignee in the newRow variable
          newRow.ownership.assignees = res.data.assignees

          // Set the state
          this.setState({
            selectUserModal: {open: true},
            selectedRow: newRow
          })
        })
    } else {
      this.setState({selectUserModal: {open: true}})
    }
  }

  handleAssign(assigneePairs) {
    this.setAssignees(assigneePairs).then(
      () => {
        // refresh in order to trigger the object listing component
        this.props.dispatch(submit('reportSearchFieldsForm'))
        showMessage('success', __('ProcessAssignedSuccessMessage'))
      }
    )

    this.closeSelectUserModal()
    this.props.closeModal()
    this.props.updateData()
  }

  getUsers(userIdsSet) {
    this.setState({isLoading: true})
    fetchUsers({ statuses: ["enabled", "reserved", "disabled", "deleted"], ids: userIdsSet})
      .then(
        (data) => {
          loadingBar.done()
          this.setState({
            userList: data,
            isLoading: false
          })
        }
      ).catch(() => this.setState({isLoading: false}))

  }

  getProcesses() {
    this.setState({isLoading: true})

    let apiUrl = `orders/${this.props.orderId}` // TODO: use .id when api is ready
    if (this.props.filterProcesses) {
      apiUrl = `orders/${this.props.orderId}?processStatuses=assigned,postponed,running,started,unassigned,rejected`
    }

    loadingBar.start()
    api.get(apiUrl)
      .then(
        (response) => {
          loadingBar.done()
          this.setState({
            order: response.data,
            isLoading: false
          })

          const userIds = response.data.processes
            .filter(({ ownership }) => !ownership.masterUserId)
            .map(({ ownership }) => ownership?.assignees.map(({ userId }) => userId))
            .flat(1)

          const uniqueUserIds = Array.from(new Set(userIds)).join()

          this.getUsers(uniqueUserIds)
        }
      ).catch(() => this.setState({isLoading: false}))
  }

  handleEdit() {
    const process = this.state.selectedRow
    api.delete(`processes/${process.id}`)
      .then(
        () => {
          this.props.dispatch(submit('reportSearchFieldsForm'))
          showMessage('success', __('ProcessEditSuccessMessage'))
          this.props.closeModal()
          this.props.updateData()
        }
      )
  }

  handleDoo() {
    const process = this.state.selectedRow
    const {location, lastLocation} = this.props
    const lastLocationRedirectTarget = {...location}
    if (lastLocation !== null) {
      lastLocationRedirectTarget.search = `?backUrl=${lastLocation.pathname}`
    }
    this.props.closeModal()
    let dest = `/orders/${process.orderId}/doo?activeProcessId=${process.id}`
    if (lastLocationRedirectTarget !== undefined) dest += `&backUrl=${lastLocationRedirectTarget.pathname}${lastLocationRedirectTarget.search}`
    hashHistory.push(dest)
  }

  setAssignees(pairs) {
    const process = this.state.selectedRow

    // TODO: delete if necessary
    // if (typeof process.ownership.masterUserId !== undefined) {
    //   delete process.ownership.masterUserId
    // }

    const data = {
      ...process.ownership,
      masterUserId: undefined,
      masterEntityId: undefined,
      masterEntity: undefined,
      master: undefined,
      assignees: pairs.map((pair) => ({
          ownershipId: process.ownership.id,
          userId: pair.userId,
          entityId: pair.entityId
        }))
    }

    return api.put(`processes/${process.id}/ownership/${process.ownership.id}`, data)
  }

  trClassFormat(row) {
    return row.accessRightsInfo.edit ? '' : 'disabled'
  }

  getNonSelectableProcesses(processes) {
    const nonSelectableProcesses = []
    if (!processes) {
      return nonSelectableProcesses
    }
    processes.forEach((p) => {
      if (!p.accessRightsInfo.edit) {
        nonSelectableProcesses.push(p.id)
      }
    })
    return nonSelectableProcesses
  }

  translateProcesses(sourceProcesses) {
    const {userList} = this.state

    return sourceProcesses?.map((p) => {
	    const uniqueAssignees = new Set();
	    const filteredAssignees = p.ownership?.assignees
		    ?.filter(assignee => {
			    if (uniqueAssignees.has(assignee.userId)) {
				    return false;
			    }
			    uniqueAssignees.add(assignee.userId);
			    return true;
		    });

			return {
				...p,
				processStatus: __(p.processStatus),
				title: __(p.title),
				...( p.ownership ? {
					ownership: {
						...p.ownership,
						master: p.ownership?.master ? [p.ownership?.master] : filteredAssignees?.map(({ userId }) => (userList?.find(u => u.id === userId))),
						assignees: p.ownership.assignees?.map(({ userId }) => (userList?.find(u => u.id === userId)))
					},
				} : {}),
			}

    }).sort((a, b) => {
      if (a.internalProcessStatus === 'assigned' && b.internalProcessStatus !== 'assigned') {
        return 1
      }
      if (b.internalProcessStatus === 'assigned' && a.internalProcessStatus !== 'assigned') {
        return -1
      }
      return (a.lastUpdateDatetime > b.lastUpdateDatetime) ? 1 : ((b.lastUpdateDatetime > a.lastUpdateDatetime) ? -1 : 0)
    })
  }

  render() {
    const {selectedRow, order} = this.state
    const {guiUser} = this.props

    const isSuperUser = checkMatchingTemplates(guiUser.matchingTemplates, ['ROOT', 'PA'])

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.handleRowSelect.bind(this),
      unselectable: this.getNonSelectableProcesses(order?.processes)
    }
    return (

      <Modal
        title={__('Edit Process')}
        className="processes-list"
        width={"80%"}
        bodyStyle={{height: 390, overflowY: 'scroll'}}
        visible={this.props.open}
        onCancel={this.props.closeModal.bind(this)}
        afterClose={document.body.removeAttribute('style')} //for bug described here: https://github.com/ant-design/ant-design/issues/21539
        footer={
          <div style={{display: 'flex', justifyContent: "space-between"}}>
            <div>
              <Button
                className="pull-left"
                onClick={this.props.closeModal.bind(this)}
              >
                {__('Back')}
              </Button>
            </div>
            <Space>
              <Button
                disabled={
                  !selectedRow
                  || selectedRow.internalProcessStatus === 'completed'
                  || selectedRow.internalProcessStatus === 'initialised'
                }
                className="btn-green"
                onClick={this.openUserSelectModal.bind(this)}
              >
                {__('Assign')}
              </Button>

              <Button
                disabled={
                  !selectedRow
                  || selectedRow.internalProcessStatus === 'initialised'
                }
                className="btn-edit"
                onClick={this.handleEdit.bind(this)}
              >
                {__('Reset')}
              </Button>

              <Button
                disabled={
                  !selectedRow || !selectedRow.accessRightsInfo?.edit
                }
                className="btn-doo"
                onClick={this.handleDoo.bind(this)}
              >
                DOO
              </Button>
            </Space>
          </div>
        }
      >
        <div>

          {!this.state.order
            && (
              <div style={{position: 'relative', height: '100px'}}>
                <Loader loaded={this.state.order !== null} shadow={false} color="#0a4f7a"/>
              </div>
            )}

          {this.state.order
            && (
              <BootstrapTable
                trClassName={this.trClassFormat.bind(this)}
                data={this.translateProcesses(this.state.order.processes.map((p) => ({
                  ...p,
                  internalOrderStatus: order.internalOrderStatus
                })))}
                selectRow={selectRow}
                ref="ordersTable"
                remote
                pagination={false}
                hover
                striped
                options={{noDataText: __('NoDataFound')}}
              >

                <TableHeaderColumn
                  isKey
                  hidden
                  dataField="id"
                />

                <TableHeaderColumn
                  dataField="title"
                >
                  {__('Process Title')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="processStatus"
                >
                  {__('Status')}
                </TableHeaderColumn>

                {
                  isSuperUser && (
                    <TableHeaderColumn dataField="internalProcessStatus">
                      {__(T.internal_process_status)}
                    </TableHeaderColumn>
                  )
                }
                {
                  isSuperUser && (
                    <TableHeaderColumn dataField="internalOrderStatus">
                      {__(T.internal_order_status)}
                    </TableHeaderColumn>
                  )
                }
                <TableHeaderColumn
                  dataSort={false}
                  dataField="responsibleUser"
                  dataFormat={(cell, row) => <UsersField users={row.ownership?.master}/>}
                >
                  {__('responsibleUser')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="lastUpdateDatetime"
                  dataFormat={(val) => moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                >
                  {__('lastModified')}
                </TableHeaderColumn>

              </BootstrapTable>
            )}
          {
            this.state.selectUserModal.open && (
              <EditOrderSelectUserModal
                open={this.state.selectUserModal.open}
                guiUser={this.props.guiUser}
                closeModal={this.closeSelectUserModal.bind(this)}
                onSubmit={this.handleAssign.bind(this)}
                process={this.state.selectedRow}
              />
            )
          }
          {/*<EditOrderSelectAssigneeModal*/}
          {/*open={this.state.selectUserModal.open}*/}
          {/*guiUser={this.props.guiUser}*/}
          {/*closeModal={this.closeSelectUserModal.bind(this)}*/}
          {/*onSubmit={this.handleAssign.bind(this)}*/}
          {/*processes={this.state.selectedRow ? [].concat(this.state.selectedRow) : []}*/}
          {/*/>*/}
        </div>
      </Modal>
    )
  }
}

const EditOrderModalConnected = connect(null, (dispatch) => ({dispatch}))(EditOrderModal)
export default withLastLocation(EditOrderModalConnected)
