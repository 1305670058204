import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { hashHistory } from '../providers/HistoryProvider';

import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

class SelectPairModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedPair: null,
      userAssignmentPairs: this.props.userAssignmentPairs || [],
    };

    this.getAvailableAssignees = this.getAvailableAssignees.bind(this);
  }

  componentDidMount() {
    // Todo: is this necessary?
    if (!this.state.userAssignmentPairs?.length) {
      this.getAvailableAssignees()
    }
  }

  getAvailableAssignees() {
    const { processId } = this.props;
    const { guiUserId } = this.props;

    if (!processId) {
      return;
    }

    api.get(`/processes/${processId}/ownership/availableAssignees?userIds=${guiUserId}`)
      .then(
        (response) => {
          this.setState({
            userAssignmentPairs: response.data,
          });
        });
  }

  /**
   * I change this in order to use the more linear code without closing the modal or setting the state twice
   * **/
  onSubmit() {
    this.props.onSubmit(JSON.parse(this.state.selectedPair))?.then(() => this.props.closeModal());
  }

  handleHide() {
      this.goToOrders();
  }

  goToOrders() {
    hashHistory.goBack();
  }

  render() {
    const { open } = this.props;

    return (
      <Modal
        backdrop="static"
        className="appointment-details"
        show={open}
        onHide={this.handleHide.bind(this)}>

        <Modal.Header closeButton>
          <Modal.Title>{__('select_master_entity_title', 'capitalize_sentence')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="step-info">
            {__('SelectPairInfoText', 'capitalise')}
          </p>

          <form className="form-horizontal">
            <div className="form-group">
              <label htmlFor="inputName"
                className="col-sm-4 control-label text-right">{__('entity', 'capitalise') + '*'}:</label>
              <div className={'col-sm-6'}>
                <select
                  onChange={(e) => {
                    let options = e.target.options;
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        this.setState({
                          selectedPair: options[i].value
                        });
                      }
                    }
                  }}
                  name="assignees"
                  className="form-control"
                >
                  <option value={''} key={'pair-select'}>{__('Select one')}</option>
                  {
                    this.state.userAssignmentPairs.map((pair) => {
                      return <option value={JSON.stringify(pair)} key={`pair-${pair.userid}-${pair.entityId}`}>{`${pair.entity.name} (${pair.entity.shortName})`}</option>;
                    })
                  }
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!this.state.selectedPair}
            className="pull-right btn-doo"
            onClick={this.onSubmit.bind(this)}
          >
            Doo
          </Button>

        </Modal.Footer>

      </Modal>
    )
  }

}

SelectPairModal.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  userAssignmentPairs: PropTypes.array,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  processId: PropTypes.string,
  guiUserId: PropTypes.string
};

export default SelectPairModal;
